import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import './../elems/common.css';
import DatePicker, {registerLocale} from "react-datepicker";
import dateFormat from "dateformat";
import ru from "date-fns/locale/ru";

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import axios from "axios";
import {Store} from "react-notifications-component";

registerLocale('ru', ru)

export default class AddAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: props.token,

            name: "",
            registered: "",
            developer: "",
            proxy: "",
            card: "",
            phone: "",
            login: "",
            password: "",
            keyPassword: "",
            status: "PUBLISHED",
            comment: ""
        }

        this.handleNameChanged = this.handleNameChanged.bind(this)
        this.handleRegisteredChanged = this.handleRegisteredChanged.bind(this)
        this.handleDeveloperChanged = this.handleDeveloperChanged.bind(this)
        this.handleProxyChanged = this.handleProxyChanged.bind(this)
        this.handleCardChanged = this.handleCardChanged.bind(this)
        this.handlePhoneChanged = this.handlePhoneChanged.bind(this)
        this.handleLoginChanged = this.handleLoginChanged.bind(this)
        this.handlePasswordChanged = this.handlePasswordChanged.bind(this)
        this.handleKeyPasswordChanged = this.handleKeyPasswordChanged.bind(this)
        this.handleStatusChanged = this.handleStatusChanged.bind(this)
        this.handleCommentChanged = this.handleCommentChanged.bind(this)
        this.addAccount = this.addAccount.bind(this)
    }

    handleNameChanged(event) {
        this.setState({
            name: event.target.value
        })
    }

    handleRegisteredChanged(date) {
        const dateString = dateFormat(date, 'yyyy-mm-dd');
        this.setState({
            registered: dateString
        })
    }

    handleDeveloperChanged(event) {
        this.setState({
            developer: event.target.value
        })
    }

    handleProxyChanged(event) {
        this.setState({
            proxy: event.target.value
        })
    }

    handleCardChanged(event) {
        this.setState({
            card: event.target.value
        })
    }

    handlePhoneChanged(value) {
        this.setState({
            phone: value
        })
    }

    handleLoginChanged(event) {
        this.setState({
            login: event.target.value
        })
    }

    handlePasswordChanged(event) {
        this.setState({
            password: event.target.value
        })
    }

    handleKeyPasswordChanged(event) {
        this.setState({
            keyPassword: event.target.value
        })
    }

    handleStatusChanged(event) {
        this.setState({
            status: event.target.value
        })
    }

    handleCommentChanged(event) {
        this.setState({
            comment: event.target.value
        })
    }

    addAccount() {
        const url = process.env.REACT_APP_DOMAIN + "/api/accounts";
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        }
        const data = {
            name: this.state.name,
            registred_at: this.state.registered,
            developer: this.state.developer,
            proxy: this.state.proxy,
            card: this.state.card,
            phone: this.state.phone,
            login: this.state.login,
            password: this.state.password,
            key_password: this.state.keyPassword,
            status: this.state.status,
            comment: this.state.comment
        }

        const notificationSuccess = {
            title: 'Success',
            message: 'Account successfully created!',
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        }

        const notificationError = {
            title: 'Error',
            message: 'Account not created',
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        }

        axios.post(url, data, config)
            .then(response => {
                console.log(response);

                if (response.status === 200) {

                    // @ts-ignore
                    Store.addNotification(notificationSuccess);

                    // this.setState({
                    //     name: "",
                    //     registered: "",
                    //     developer: "",
                    //     proxy: "",
                    //     card: "",
                    //     phone: "",
                    //     login: "",
                    //     password: "",
                    //     keyPassword: "",
                    //     status: "PUBLISHED",
                    //     comment: ""
                    // });
                } else {
                    Store.addNotification(notificationError);
                }

            })
            .catch(error => Store.addNotification(notificationError));
    }

    render() {
        return <div className="centered-outside">
            <table>
                <tr>
                    <td>Name:</td>
                    <td><input type="text" className="form-control margin-small" placeholder="Account name" onChange={this.handleNameChanged}/></td>
                </tr>
                <tr>
                    <td>Registered at:</td>
                    <td>
                        <DatePicker
                            className='form-control event-form-input margin-small'
                            selected={Date.parse(this.state.registered)}
                            locale='ru'
                            dateFormat="yyyy-MM-dd"
                            onChange={(date) => {
                                this.handleRegisteredChanged(date)
                            }}/>
                    </td>
                </tr>
                <tr>
                    <td>Developer:</td>
                    <td><input type="text" className="form-control margin-small" placeholder="Developer name" onChange={this.handleDeveloperChanged}/></td>
                </tr>
                <tr>
                    <td>Proxy:</td>
                    <td><input type="text" className="form-control margin-small" placeholder="Proxy" onChange={this.handleProxyChanged}/></td>
                </tr>
                <tr>
                    <td>Card:</td>
                    <td><input type="text" className="form-control margin-small" placeholder="Card" onChange={this.handleCardChanged}/></td>
                </tr>
                <tr>
                    <td>Phone:</td>
                    <td>
                        <PhoneInput
                            className="form-control margin-small"
                            placeholder="Phone number"
                            value={this.state.phone}
                            onChange={this.handlePhoneChanged}/>
                    </td>
                </tr>
                <tr>
                    <td>Login:</td>
                    <td><input type="email" className="form-control margin-small" placeholder="Developer login" onChange={this.handleLoginChanged}/></td>
                </tr>
                <tr>
                    <td>Password:</td>
                    <td><input type="text" className="form-control margin-small" placeholder="Password" onChange={this.handlePasswordChanged}/></td>
                </tr>
                <tr>
                    <td>Key password:</td>
                    <td><input type="text" className="form-control margin-small" placeholder="Key password" onChange={this.handleKeyPasswordChanged}/></td>
                </tr>
                <tr>
                    <td>Status:</td>
                    <td>
                        <select className="form-select margin-small" onChange={this.handleStatusChanged}>
                            <option value="PUBLISHED" selected>PUBLISHED</option>
                            <option value="BANNED">BANNED</option>
                            <option value="BLOCKED">BLOCKED</option>
                            <option value="DRAFT">DRAFT</option>
                            <option value="MODERATION">MODERATION</option>
                            <option value="ARCHIVE">ARCHIVE</option>
                            <option value="DECLINED">DECLINED</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>Comments:</td>
                    <td><input type="text" className="form-control margin-small" placeholder="Comment" onChange={this.handleCommentChanged}/></td>
                </tr>
            </table>
            <button className="btn btn-primary" onClick={this.addAccount} disabled={
                !(this.state.name && this.state.registered && this.state.developer && this.state.proxy
                    && this.state.card && this.state.phone && this.state.login && this.state.password
                    && this.state.keyPassword && this.state.status)
            }>Create</button>
        </div>
    }
}
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './subs-dialog.css';
import '../../common.css';
import {Modal} from "react-bootstrap";
import axios from "axios";
import {Store} from "react-notifications-component";

class SubDayRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: props.date,
            value: props.value
        }

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        if (event.target.validity.valid) {
            this.setState({
                value: event.target.value
            }, () => {
                this.props.onChange(this.state.date, this.state.value);
            });
        } else {
            event.preventDefault();
        }
    }

    render() {
        // noinspection JSCheckFunctionSignatures
        return (
            <div className="input-group sub-day">
                <div className="input-group-prepend">
                    <div className="input-group-text">{this.state.date.replaceAll('-', '.')}</div>
                </div>
                <input type="text"
                       pattern="[0-9,.-]*"
                       className="form-control"
                       value={this.state.value === 0 ? '' : this.state.value}
                       onChange={this.handleChange}/>
                <div className="input-group-prepend">
                    <div className="input-group-text">$</div>
                </div>
            </div>
        );
    }
}

function getDaysInMonth(month, year) {
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }
    return days;
}

class SubDaysList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            year: props.year,
            month: props.month,
            subDays: props.subDays
        }

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(date, value) {
        this.props.onChange(date, value);
    }

    render() {
        const days = getDaysInMonth(this.state.month, this.state.year);
        const dateCompareOptions = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        }
        return (
            days.map(day => {
                    // noinspection JSCheckFunctionSignatures
                    const dateAsString = day.toLocaleString('ru-RU', dateCompareOptions).replaceAll('.', '-');
                    let value = '';
                    for (let i = 0; i < this.state.subDays.length; i++) {
                        if (this.state.subDays[i].report_date === dateAsString) {
                            value = this.state.subDays[i].income;
                        }
                    }
                    return <SubDayRow key={dateAsString} date={dateAsString} value={value} onChange={this.handleChange}/>
                }
            )
        );
    }
}

function addMonths(date, months) {
    const d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() !== d) {
        date.setDate(0);
    }
    return date;
}

class DialogSubs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: props.token,
            projectId: props.projectId,
            date: new Date(),
            subs: [],
            subsForUpdate: [],
            isLoaded: false,
            error: null
        }
        this.handleMonthInc = this.handleMonthInc.bind(this);
        this.handleMonthDec = this.handleMonthDec.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubChange = this.handleSubChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    loadSubsInfo() {
        const days = getDaysInMonth(this.state.date.getMonth(), this.state.date.getFullYear());

        const dayFrom = days[0];
        const fromDateFormat = dayFrom.getFullYear() + "-" + ("0" + (dayFrom.getMonth() + 1)).slice(-2) + "-" + ("0" + dayFrom.getDate()).slice(-2);

        const dayTo = days[days.length - 1];
        const toDateFormat = dayTo.getFullYear() + "-" + ("0" + (dayTo.getMonth() + 1)).slice(-2) + "-" + ("0" + dayTo.getDate()).slice(-2);

        const url = process.env.REACT_APP_DOMAIN + '/api/projects/' + this.state.projectId + '?from_date=' + fromDateFormat + '&to_date=' + toDateFormat;
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        }
        axios.get(url, config)
            .then(response => {
                const subs = [];
                const keys = Object.keys(response.data.subs_data);
                keys.forEach(key => {
                    const year = response.data.subs_data[key].report_date.split('-')[0];
                    const month = response.data.subs_data[key].report_date.split('-')[1];
                    const day = response.data.subs_data[key].report_date.split('-')[2];
                    const reportDate = day + '-' + month + '-' + year;
                    let income = response.data.subs_data[key].income_usd;
                    this.state.subsForUpdate.forEach(sub => {
                        if (sub.report_date === reportDate) {
                            income = sub.income;
                        }
                    })
                    subs.push({
                        report_date: reportDate,
                        income: income
                    })
                });
                this.state.subsForUpdate.forEach(subForUpdate => {
                    let exists = false;
                    subs.forEach(sub => {
                        if (sub.report_date === subForUpdate.report_date) {
                            exists = true;
                        }
                    })
                    if (!exists) {
                        subs.push({
                            report_date: subForUpdate.report_date,
                            income: subForUpdate.income
                        })
                    }
                })
                this.setState({
                    subs: subs,
                    isLoaded: true
                });
            })
            .catch(error => {
                this.setState({
                    isLoaded: true,
                    error: error
                })
            })
    }

    componentDidMount() {
        this.loadSubsInfo();
    }

    handleMonthInc() {
        const newDate = addMonths(this.state.date, 1);
        this.setState({
            date: newDate,
            isLoaded: false,
            error: null
        }, () => {
            this.loadSubsInfo();
        });
    }

    handleMonthDec() {
        const newDate = addMonths(this.state.date, -1);
        this.setState({
            date: newDate,
            isLoaded: false,
            error: null
        }, () => {
            this.loadSubsInfo();
        });
    }

    handleClose() {
        this.props.onClose();
    }

    handleSubChange(date, value) {
        let exists = false;
        let newSubs = this.state.subsForUpdate;
        for (let i = 0; i < newSubs.length; i++) {
            if (newSubs[i].report_date === date) {
                newSubs[i].income = value;
                exists = true;
                break;
            }
        }
        if (!exists) {
            newSubs.push({
                report_date: date,
                income: value
            })
        }
        this.setState({
            subsForUpdate: newSubs
        });
    }

    handleSave() {
        const notificationSuccess = {
            title: 'Subscriptions',
            message: 'Data successfully updated',
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        }
        const notificationError = {
            ...notificationSuccess,
            message: 'Error occurred while updating data',
            type: 'danger'
        }

        const url = process.env.REACT_APP_DOMAIN + '/api/projects/' + this.state.projectId + '/subscriptions';
        const requestSubs = this.state.subsForUpdate;
        const requestData = [];
        requestSubs.forEach(sub => {
            const day = sub.report_date.split('-')[0];
            const month = sub.report_date.split('-')[1];
            const year = sub.report_date.split('-')[2];
            requestData.push({
                report_date: year + '-' + month + '-' + day,
                income: sub.income === '' ? 0 : sub.income
            })
        });
        const requestConfig = {
            headers: {
                'content-type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        }
        axios.post(url, requestData, requestConfig)
            .then(result => {
                if (result.data.status === 'success') {
                    this.props.onDataUpdated();
                    Store.addNotification(notificationSuccess);
                    this.handleClose();
                } else {
                    Store.addNotification(notificationError)
                }
            })
            .catch(() => {
                Store.addNotification(notificationError);
            });
    }

    render() {
        const monthOptions = {
            month: 'long'
        }
        return (
            <Modal show={true} onHide={this.handleClose}>
                <Modal.Header>
                    <Modal.Title>Subscriptions management</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className='centered month'>
                        <i className="fas fa-arrow-left month-selector" onClick={this.handleMonthDec}/>
                        &emsp;
                        {this.state.date.toLocaleString('ru-RU', monthOptions)}
                        &emsp;
                        <i className="fas fa-arrow-right month-selector" onClick={this.handleMonthInc}/>
                    </div>
                    {this.state.error ?
                        <div>{this.state.error.message}</div>
                        : !this.state.isLoaded ?
                            <div>Loading...</div>
                            :
                            <SubDaysList key={this.state.date.getMonth()} subDays={this.state.subs}
                                         month={this.state.date.getMonth()} year={this.state.date.getFullYear()}
                                         onChange={this.handleSubChange}/>
                    }
                </Modal.Body>

                <Modal.Footer>
                    <button className='btn btn-secondary' onClick={this.handleClose}>Cancel</button>
                    <button className='btn btn-primary' onClick={this.handleSave}
                            disabled={this.state.subsForUpdate.length === 0}>Save changes
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }

}

export default DialogSubs
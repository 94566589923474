import React, {Component} from 'react'
import {HotTable} from '@handsontable/react';
import axios from "axios";
//import Handsontable from "handsontable";
import {Store} from 'react-notifications-component';

export default class CustomTable extends Component<any, any> {
    private hotTableComponent: any;

    constructor(props: any) {
        super(props);

        this.hotTableComponent = React.createRef();
        var self = this
        this.state = {
            token: props.token,
            accounts: [],
            settings: {
                data: this.props[0],
                colHeaders: ["ID", "Account", "App name", "Bundle", "Host code", "Status", "Ads", "AdMob",
                    "Approved", "Sub", "Installs", "Crashes", "Rating", "API key", "Post API key"],
                dataSchema: {
                    appmetrica_id: null,
                    account: null,
                    name: null,
                    bundle: null,
                    host_code: null,
                    status: null,
                    ads: null,
                    admob_name: null,
                    admob_approved: null,
                    subscription: null,
                    installs: null,
                    crashes: null,
                    api_key: null,
                    post_api_key: null
                },
                renderAllRows: true,
                viewportRowRenderingOffset: 600,
                startCols: 11,
                rowHeaders: true,
                dropdownMenu: true,
                width: '100%',
                filters: true,
                columnSorting: true,
                licenseKey: "non-commercial-and-evaluation",
                afterRenderer: function (td: any, row: any, column: any, prop: any, value: any, cellProperties: any) {
                    row = this.getDataAtRow(row)
                    switch (row[5]) {
                        case "PUBLISHED":
                            td.className = 'bg-success'
                            break;
                        case "BANNED":
                            td.className = 'bg-danger'
                            break;
                        case "DRAFT":
                            td.className = 'bg-secondary'
                            break;
                        case "MODERATION":
                            td.className = 'bg-warning'
                            break;
                        case "ARCHIVE":
                            td.className = 'bg-light'
                            break;
                        case "DECLINED":
                            td.className = 'bg-warning'
                            break;
                    }
                    //installs & ads
                    if (row[10] >= 400 && row[6] === 0) {
                        td.className = 'bg-info'
                    }
                    //status & host_code
                    if (row[5] === "PUBLISHED" && row[4] === 404) {
                        td.className = 'bg-attention'
                    }
                },
                afterChange: (changes: any, source: any) => {
                    // @ts-ignore
                    if (changes) {
                        var currentRow = changes[0][0];
                        var appId = self.hotTableComponent.current.hotInstance.getDataAtCell(currentRow, 0)
                        changes.forEach(([row, prop, oldValue, newValue]: any) => {
                            if (oldValue !== newValue) {

                                if (prop === "account" && newValue !== ""
                                    && !this.state.accounts.map((account: { name: any; }) => account.name).includes(newValue)) {

                                    const notificationError = {
                                        title: 'Error',
                                        message: 'Account "' + newValue + '" not found',
                                        type: "danger",
                                        insert: "top",
                                        container: "top-right",
                                        animationIn: ["animate__animated", "animate__fadeIn"],
                                        animationOut: ["animate__animated", "animate__fadeOut"],
                                        dismiss: {
                                            duration: 5000,
                                            onScreen: true
                                        }
                                    }
                                    // @ts-ignore
                                    Store.addNotification(notificationError);
                                } else {

                                    const notificationError = {
                                        title: 'Error',
                                        message: 'Changes are not committed',
                                        type: "danger",
                                        insert: "top",
                                        container: "top-right",
                                        animationIn: ["animate__animated", "animate__fadeIn"],
                                        animationOut: ["animate__animated", "animate__fadeOut"],
                                        dismiss: {
                                            duration: 5000,
                                            onScreen: true
                                        }
                                    }

                                    if (prop === "account") {
                                        prop = "account_id";
                                        if (newValue === "") {
                                            newValue = null;
                                        } else {
                                            newValue = this.state.accounts.find((account: { name: any; }) => account.name === newValue).id;
                                        }
                                        console.log(newValue);
                                    }

                                    const url = process.env.REACT_APP_DOMAIN + '/api/apps/' + appId;
                                    const config = {
                                        headers: {
                                            Accept: 'application/json',
                                            Authorization: 'Bearer ' + this.state.token
                                        }
                                    }

                                    axios.put(url, {
                                        [prop]: newValue
                                    }, config).then(response => {
                                        console.log(response)
                                        if (response.status !== 200) {
                                            // @ts-ignore
                                            Store.addNotification(notificationError);
                                        }
                                    }).catch(error => {
                                        // @ts-ignore
                                        Store.addNotification(notificationError);
                                    });
                                }
                            }
                        });
                    }
                },
                columns: [
                    {
                        type: 'numeric',
                        readOnly: true,
                        data: 'appmetrica_id',
                        renderer: function (instance: any, td: any, row: any, column: any, prop: any, value: any, cellProperties: any) {
                            td.innerHTML = '<a target="_blank" href="https://appmetrica.yandex.ru/statistic?appId='
                                + value + '&report=audience">' + value + '</a>'
                        }
                    },
                    {
                        data: 'account',
                    },
                    {
                        data: 'name'
                    },
                    {
                        data: 'bundle'
                    },
                    {
                        data: 'host_code',
                        readOnly: true,
                    },
                    {
                        data: 'status',
                        type: 'dropdown',
                        source: ['DRAFT', 'MODERATION', 'PUBLISHED', 'DECLINED', 'BANNED', 'ARCHIVE']
                    },
                    {
                        data: 'ads',
                        type: 'dropdown',
                        source: [0, 1]
                    },
                    {
                        data: 'admob_name',
                        type: 'dropdown',
                        source: ["Main", "Second", "Oleg"]
                    },
                    {
                        data: 'admob_approved',
                        type: 'dropdown',
                        source: [0, 1]
                    },
                    {
                        data: 'subscription',
                        type: 'dropdown',
                        source: [0, 1]
                    },
                    {
                        data: 'installs',
                        readOnly: true
                    },
                    {
                        data: 'crashes',
                        readOnly: true,
                        renderer: function (instance: any, td: any, row: any, column: any, prop: any, value: any, cellProperties: any) {
                            td.innerHTML = '<a target="_blank" href="https://appmetrica.yandex.ru/statistic?appId='
                                + instance.getSourceDataAtRow(row).appmetrica_id + '&report=audience">' + value + '</a>'
                        }
                    },
                    {
                        data: 'rating',
                        readOnly: true
                    },
                    {
                        data: 'api_key'
                    },
                    {
                        data: 'post_api_key'
                    }
                ],
            }
        }
    }

    componentDidMount() {
        const url = process.env.REACT_APP_DOMAIN + '/api/accounts';
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        }

        axios.get(url, config)
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        accounts: response.data
                    })
                    console.log(this.state.accounts);
                }
            })
            .catch()
    }

    render() {
        return (
            <div>
                <HotTable ref={this.hotTableComponent} settings={this.state.settings}/>
            </div>
        )
    }
}

import React, {Component} from 'react'
import axios from "axios";
import CustomTable from "./CustomTable";
import { Link } from "react-router-dom"
import "./style.css";

export default class AppsList extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            token: props.token,
            data: []
        }
    }

    tick() {
        const url = process.env.REACT_APP_DOMAIN + '/api/apps';
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        }

        axios.get(url, config)
            .then(response => {
                //@ts-ignore
                response.data.forEach(item => item.account = item.account ? item.account.name : "");
                console.log(response.data);
                this.setState({
                    data: [response.data]

                })
            })
            .catch((e) => {
                // window.location.href = "login"
            });
    }

    componentDidMount() {
        this.tick()
    }

    render() {
        if (this.state.data.length > 0) {
            return (
                <div id="hot-app" className="container">
                    <div className="row">
                        <div className="col-12 d-flex flex-row">
                            <h2 className="">Список приложений</h2>

                            <Link to={"/apps/add"}><button className="btn btn-outline-primary ms-3">Добавить приложение</button></Link>
                        </div>

                    </div>
                    <div className="row mt-4">
                        <div className="col-12">
                            <div id="hot-app">
                                <CustomTable {...this.state.data} token={this.state.token}/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div id="hot-app" className="container">
                    <div className="row">
                        <div className="col-10">
                            <div className="d-flex flex-row align-items-baseline">
                                <span className="spinner-grow spinner-grow-sm me-1" role="status" aria-hidden="true"/>
                                <h3 className="text-secondary">Загрузка...</h3>
                            </div>

                            <p className="text-warning fs-6">Если долго висит, возможно ошибка</p>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

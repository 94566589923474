import React, {Component} from 'react'
import axios from "axios";
import Highcharts from 'highcharts'
import '../elems/common.css';
import {map} from "underscore";
import "./style.css";

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

interface State {
    token: any,

    data: {
        published: "none",
        ads_required: "none",
        moderation: "none",
        draft: "none",
        banned: "none",
        attention: "none",
        archived: "none"
    },

    installStats: { date: any, installs: any }[],
    accounts: any[],

    fromDate: any,
    toDate: any
}

const QUIK_LINKS = [
    { emoji: "📈", title: 'Дашборд по прилкам в экселе', href: 'https://docs.google.com/spreadsheets/d/1K8xBH-VSzCEb_3f153fR3JIIW9MORQkecPw7-QkV0t0/edit#gid=1143156577'},
    { emoji: "📅", title: '2022 - годовой план', href: 'https://docs.google.com/spreadsheets/d/1-8S_cC5hW8ByEJhVTj_rvdx0-4vaP987wFIebupXr44/edit#gid=117388946'},
    { emoji: "🗿", title: 'Рекламные креативы', href: 'https://docs.google.com/spreadsheets/d/1BcWqOeMAksT1RTzB9bWum4HAqAKN1k0JIegAVYfDli0/edit#gid=0'},
    { emoji: "🏞️", title: 'A/B тесты графики', href: 'https://docs.google.com/spreadsheets/d/1DcaZo2P98WjJd4AbLATef4ZZNikl_yZWDVELn8TkijY/edit#gid=0'},
    { emoji: "🎟️", title: 'А/Б тесты подписок', href: 'https://docs.google.com/spreadsheets/d/1ouNriuGFHyy8h7WpiEVu_5acW7YjBgbmvL7bt-S3q2g/edit#gid=0'},
    { emoji: "🚦", title: 'Affiliates', href: process.env.REACT_APP_AFFILIATES_DOMAIN},
    { emoji: "🪣", title: 'Bucket', href: process.env.REACT_APP_BUCKET_DOMAIN},
    { emoji: "🐍", title: 'Hydra', href: process.env.REACT_APP_HYDRA_DOMAIN},
    { emoji: "📡", title: 'Scan', href: process.env.REACT_APP_SCAN_DOMAIN},
    { emoji: "🗂", title: 'YouTrack утилиты', href: process.env.REACT_APP_DOMAIN + "/yt"},
]

export default class Overview extends Component<any, State> {

    private timerID: any;
    private readonly chart: any;

    constructor(props: any | State) {
        super(props);

        this.state = {
            token: props.token,

            data: {
                published: "none",
                ads_required: "none",
                moderation: "none",
                draft: "none",
                banned: "none",
                attention: "none",
                archived: "none"
            },

            installStats: [],
            accounts: [],

            fromDate: localStorage.getItem('fromDateOverall') ? new Date(parseInt(localStorage.getItem('fromDateOverall') as string)) : new Date(),
            toDate: localStorage.getItem('toDate') ? new Date(parseInt(localStorage.getItem('toDateOverall') as string)) : new Date(),
        }

        this.chart = React.createRef();

        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
        this.loadInstallStats = this.loadInstallStats.bind(this);
        this.loadAccounts = this.loadAccounts.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.updateAccount = this.updateAccount.bind(this);
    }

    handleLogout() {
        this.props.onLogout();
    }

    handleDateRangeChange(fromDate: any, toDate: any) {
        this.setState({
            fromDate: fromDate,
            toDate: toDate
        });
        localStorage.setItem('fromDateOverall', fromDate.getTime());
        localStorage.setItem('toDateOverall', toDate.getTime());
    }

    componentDidMount() {
        this.tick()
        this.timerID = setInterval(
            () => this.tick(),
            60000
        );
        this.loadAccounts();
    }

    loadAccounts() {
        const url = process.env.REACT_APP_DOMAIN + '/api/accounts/phones';
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        }

        axios.get(url, config)
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        accounts: response.data
                    })
                }
            })
            .catch()
    }

    updateAccount(account: any, token: any, onFinish: any) {
        return function () {
            const url = process.env.REACT_APP_DOMAIN + '/api/accounts/' + account.id + '/phone';
            const config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
            axios.put(url, {}, config)
                .then(response => {
                    console.log(response);
                    if (response.status === 200) {
                        onFinish();
                    }
                });
        };
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    loadInstallStats() {

        const diffDays = (this.state.toDate.getTime() - this.state.fromDate.getTime()) / (24 * 60 * 60 * 1000);

        const fromDate = new Date();
        fromDate.setDate(this.state.fromDate.getDate() - diffDays);
        const toDate = this.state.toDate;

        const fromDateFormat = fromDate.getFullYear() + "-" + ("0" + (fromDate.getMonth() + 1)).slice(-2) + "-" + ("0" + fromDate.getDate()).slice(-2);
        const toDateFormat = toDate.getFullYear() + "-" + ("0" + (toDate.getMonth() + 1)).slice(-2) + "-" + ("0" + toDate.getDate()).slice(-2);

        console.log(fromDateFormat);
        console.log(toDateFormat);


        const url = process.env.REACT_APP_DOMAIN + "/api/get-overall-installs?from=" + fromDateFormat + "&to=" + toDateFormat;
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        }

        axios
            .get(url, config)
            .then(response => {
                console.log(response.data);

                let newInstallStats: any = [];

                Object.keys(response.data).forEach((date: any) => {
                    newInstallStats.push({
                        date: date,
                        installs: response.data[date]
                    });
                });

                newInstallStats.sort(function (first: any, second: any) {
                    if (first.date > second.date) {
                        return 1;
                    } else if (first.date < second.date) {
                        return -1;
                    } else {
                        return 0;
                    }
                });

                this.setState({
                    installStats: newInstallStats
                })
            })
            .catch(() => {
                window.location.href = "login"
            });
    }

    tick() {
        const url = process.env.REACT_APP_DOMAIN + '/api/apps/overview';
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        }

        axios
            .get(url, config)
            .then(response => {
                console.log(response)
                this.setState({
                    data: response.data
                });
            })
            .catch(() => {
                window.location.href = "login"
            });

        this.loadInstallStats()
    }

    render() {
        let published, moderation, draft, banned, attention, archived, ads_required;
        if (this.state.data.published === "none") {
            published = moderation = attention = archived = ads_required =
                draft = banned = <i className='bi bi-hourglass-split'/>;
        } else {
            published = this.state.data.published
            moderation = this.state.data.moderation
            draft = this.state.data.draft
            banned = this.state.data.banned
            attention = this.state.data.attention
            archived = this.state.data.archived
            ads_required = this.state.data.ads_required
        }

        const datesArr1: any[] = [];
        const datesArr2: any[] = [];
        const installsArr1: any[] = [];
        const installsArr2: any[] = [];

        for (let i = 0; i < this.state.installStats.length; i++) {
            if (i < this.state.installStats.length / 2) {
                datesArr1.push(this.state.installStats[i].date)
                installsArr1.push(this.state.installStats[i].installs)
            } else {
                datesArr2.push(this.state.installStats[i].date)
                installsArr2.push(this.state.installStats[i].installs)
            }
        }

        // noinspection JSUnusedGlobalSymbols
        // const chartOptions = {
        //     title: {
        //         text: 'Всего установок'
        //     },
        //     xAxis: [{
        //         tickInterval: 1,
        //         labels: {
        //             enabled: true,
        //             formatter: function (): any {
        //                 try {
        //                     // @ts-ignore
        //                     return datesArr2[this.value].split('-')[2] + "." + datesArr2[this.value].split('-')[1];
        //                 } catch (e) {
        //                     return "";
        //                 }
        //             }
        //         }
        //     },
        //         {
        //             tickInterval: 1,
        //             labels: {
        //                 enabled: true,
        //                 formatter: function (): any {
        //                     try {
        //                         // @ts-ignore
        //                         return datesArr1[this.value].split('-')[2] + "." + datesArr1[this.value].split('-')[1];
        //                     } catch (e) {
        //                         return "";
        //                     }
        //                 }
        //             }
        //         }],
        //     yAxis: [{title: ''}],
        //     colors: ['#084298', '#CFE2FF'],
        //     series: [
        //         {
        //             name: 'current',
        //             data: installsArr1,
        //             yAxis: 0,
        //             xAxis: 0
        //         },
        //         {
        //             name: 'previous',
        //             data: installsArr2,
        //             yAxis: 0,
        //             xAxis: 1
        //         }
        //     ],
        //     exporting: {
        //         buttons: {
        //             contextButton: {
        //                 symbol: null,
        //                 menuItems: null,
        //                 text: 'Download PNG',
        //                 onclick: function() {
        //                     // @ts-ignore
        //                     const image = this.exportChart({
        //                         type: 'image/png'
        //                     });
        //                     console.log(image);
        //                 }
        //             }
        //         }
        //     }
        // }

        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2>Овервью</h2>
                    </div>
                </div>

                <div className="col-xl-6 col-md-8">
                    <div className="row mt-4">
                        <div className="col-xl-4 col-md-6">
                            <div className="overview-card apps-published" role="alert">
                                <img src="/images/published.svg" alt=""/>
                                <h1 className="alert-heading">
                                    {published}
                                </h1>
                                <h4 className="alert-heading">Опубиковано</h4>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="overview-card apps-moderation">
                                <img src="/images/moderation.svg" alt=""/>
                                <h1 className="alert-heading">{moderation}</h1>
                                <h4 className="alert-heading">На модерации</h4>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="overview-card apps-attention">
                                <img src="/images/attention.svg" alt=""/>
                                <h1 className="alert-heading">{attention}</h1>
                                <h4 className="alert-heading">Внимание</h4>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="overview-card apps-ads">
                                <img src="/images/ads_required.svg" alt=""/>
                                <h1 className="alert-heading">{ads_required}</h1>
                                <h4 className="alert-heading">Требуется реклама</h4>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="overview-card apps-draft">
                                <img src="/images/in_develop.svg" alt=""/>
                                <h1 className="alert-heading">{draft}</h1>
                                <h4 className="alert-heading">В разработке</h4>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="overview-card apps-banned">
                                <img src="/images/banned.svg" alt=""/>
                                <h1 className="alert-heading">{banned}</h1>
                                <h4 className="alert-heading">Заблокировано</h4>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="overview-card apps-archive">
                                <img src="/images/archive.svg" alt=""/>
                                <h1 className="alert-heading">{archived}</h1>
                                <h4 className="alert-heading">В архиве</h4>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-xxl-6 col-md-12">
                        <div className="card quick-links scroll">
                            <h2>Быстрые ссылки</h2>
                            <ul>
                                {map(QUIK_LINKS, ({  emoji,title, href }) => (
                                    <li className="list-group-item">
                                        <span>{emoji} <a href={href} className="">{title}</a></span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className="col-xxl-6 col-md-12">
                        <div className="card quick-links scroll">
                            <h2>Обновление аккаунтов</h2>
                            <table className="table">
                                <thead>
                                <th>Аккаунт</th>
                                <th>Телефон</th>
                                <th>Статус</th>
                                </thead>
                                <tbody>
                                {this.state.accounts.map(account => {

                                    const button = <button
                                        className={"btn btn-warning btn-sm"}
                                        onClick={this.updateAccount(account, this.state.token, this.loadAccounts)}>Update
                                    </button>

                                    return <tr key={account.id}>
                                        <td>{account.name}</td>
                                        <td>{account.phone}</td>
                                        <td>{button}</td>
                                    </tr>

                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

               <div className="row">
                   <div className='button-logout-holder'>
                       <button className='btn btn-outline-danger' onClick={this.handleLogout}>Выйти</button>
                   </div>
               </div>

            </div>
        )
    }
}